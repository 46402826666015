<template>
  <div class="wrapper row" :style="style">
    <div
      v-if="order.header"
      :class="`col wrapper__header ${checkOrder('header')}`"
    >
      <slot name="header"></slot>
    </div>
    <div v-if="order.body" :class="`col wrapper__body ${checkOrder('body')}`">
      <slot name="body"></slot>
    </div>
    <div
      v-if="order.sidebar"
      :class="`col wrapper__sidebar ${checkOrder('sidebar')}`"
    >
      <slot name="sidebar"></slot>
    </div>
    <div
      v-if="order.extraSidebar"
      :class="`col wrapper__extraSidebar ${checkOrder('extraSidebar')}`"
    >
      <slot name="extra-sidebar"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentContainer",
  data() {
    return {};
  },
  props: {
    order: {
      type: Object,
      default: () => {
        return { header: 0, body: 1, sidebar: 2, extraSidebar: 0 };
      },
    },
    sidebarWidth: {
      type: String,
      default: "362px",
    },
    marginBottom: {
      type: String,
      default: "1.5rem",
    },
  },
  computed: {
    style() {
      return {
        "--sidebar-width": this.sidebarWidth,
        "--body-width": this.order.sidebar
          ? "calc(100% - var(--sidebar-width))"
          : "100%",
        "--margin-bottom": this.marginBottom,
      };
    },
  },
  methods: {
    checkOrder(key) {
      return this.order && this.order[key]
        ? "order-" + String(this.order[key])
        : "";
    },
  },
  watch: {
    sidebarWidth() {
      this.style["--sidebar-width"] = this.sidebarWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.wrapper {
  &__body {
    flex: 1 1 100%;
    max-width: 100%;
    margin-bottom: var(--margin-bottom);

    @media (min-width: 992px) {
      flex: 1 1 var(--body-width);
      max-width: var(--body-width);
    }
  }

  &__sidebar {
    margin-bottom: var(--margin-bottom);

    @media (min-width: 992px) {
      flex: 0 0 var(--sidebar-width);
      max-width: var(--sidebar-width);
      // position: sticky;
      // top: 70px;
      // height: calc(100vh - 100px);
      // overflow: auto;

      // &::-webkit-scrollbar {
      //     width: $scrollbar_width;
      // }

      // &::-webkit-scrollbar-track {
      //     background: $light_gray;
      // }

      // &::-webkit-scrollbar-thumb {
      //     background-color: $navy;
      // }
    }
  }
}
</style>
