<template>
    <BaseCard class="circle-counter row" padding="0 0 25px">

        <div class="col-12 circle-counter__header">
			{{ title }}
		</div>

        <div class="col-8 circle-counter__chart">
            <DoughnutChart :data="chartValues" :options="options"/>
        </div>

        <div class="col-4 circle-counter__values">
            <div class="counter" v-for="( item, idx ) in data.values" :key="idx" :style="{ color: item.color }">
                <font-awesome-icon :icon="item.icon" class="counter__icon"/>
                <span class="counter__value">{{ item.value }}</span>
            </div>
        </div>

    </BaseCard>
</template>

<script>
import BaseCard from "@/components/Cards/BaseCard"
import DoughnutChart from "@/components/Chart/DoughnutChart"

export default {
    name: "CircleCounter",
	components: {
		DoughnutChart,
		BaseCard,
	},
	props: {
		data: {
			type: Object,
			default: () => {}
		},
		title: {
			type: String,
			default: ''
		},
	},
	computed: {
		chartValues() {
			let output = [];
			let agregation = 0;
			this.data.values.forEach( element => {
				agregation += Number( element.value );
			});

			this.data.values.forEach( element => {
				output.push( {
					value:  Math.round( 100 * Number( element.value ) / agregation ),
					color: element.color
				} )
			});
			return output;
		},
		options() {
			return {
				cutoutPercentage: 90,
				// responsive: false,
				maintainAspectRatio: false,
			}
		},
	},
};
</script>

<style lang='scss' scoped>

.circle-counter {

	&__header {
		font-family: 'Oswald', sans-serif;
		line-height: 50px;
		margin-bottom: 15px;
		border-bottom: 1px solid $light_gray;
	}

	// &__chart {}

	&__values {
		display: flex;
		flex-direction: column;
		justify-content: center;

		.counter {
			margin-bottom: 8px;
			margin-top: 8px;

			&__icon {
				margin-right: 12px;
			}
		}

	}
}

</style>