<template>
	<div class="doughnut-chart">
		<canvas ref="canvas"></canvas>
		<div class="doughnut-chart__values">
			<div class="value" v-for="( item, idx ) in data" :key="idx" :style="{ color: item.color }">
				{{ item.value }} %
			</div>
		</div>
	</div>
</template>

<script>
import { Doughnut } from "vue-chartjs";

export default {
    extends: Doughnut,
	name: 'DoughnutChart',
	props: {
		data: {
			type: Array,
			default: () => []
		},
		options: {
			type: Object,
			default: () => {}
		},
	},
    async mounted() {
		this.setup();
    },
	computed: {
		chartData() {
			let output = {
				datasets: [{
					data: [],
					backgroundColor: [],
					// hoverOffset: 4,
					// weight: 5
				}]
			}
			this.data.forEach(element => {
				output.datasets[0].data.push(element.value);
				output.datasets[0].backgroundColor.push(element.color);
			});
			return output;
		}
	},
	methods: {
		setup() {
			// let data = {
			// 	datasets: [{
			// 		label: 'My First Dataset',
			// 		data: [40, 60],
			// 		backgroundColor: [
			// 		'rgb(0, 184, 148)',
			// 		'rgb(255, 179, 30)'
			// 		],
			// 		hoverOffset: 4
			// 	}]
			// }
			// let options = {
			// 	cutoutPercentage: 90,
			// 	// responsive: false,
			// 	maintainAspectRatio: false,
			// }
			this.renderChart(this.chartData, this.options);
		}
	}
}
</script>

<style lang='scss' scoped>

.doughnut-chart {
	position: relative;

	&__values {
		position: absolute;
		top: 55%;
		left: 50%;
		transform: translate( -50%, -50% );
	}
}

</style>