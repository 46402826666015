<template>
	<div class="analytics content-padding">
		<ContentContainer v-if="allSet" :order="{ body: 1, sidebar: 2 }">
			<template slot="body">
				<h2>{{ labels.cahrt }}</h2>
				<BaseCard class="p-0 p-sm-2 p-md-4" margin="0 0 25px">
					<LineChart />
				</BaseCard>
				<p>{{ labels.firstLabel }}</p>
				<LabelButtonCard
					:list="LabelsList"
					header-title="Scroll depth"
					class="mb-4"
				/>
	  		</template>

	  		<template slot="sidebar">
				<BaseCard class="mb-4">
					<h5>
						{{ labels.date_range }}
					</h5>
					<FormulateInput v-model="fromFilter" :label="labels.from" type="date" :value="today" />
					<FormulateInput v-model="toFilter" :label="labels.to" type="date" :value="today" />
				</BaseCard>
				<BaseCard class="mb-4">
					<h5>
						{{ labels.type_of_analytics }}
					</h5>
					<FormulateInput type="select" v-model="selectedAnalyticsType" :options="availableAnalytics" />
					<div v-for="(value, name, index) in typesOfAnalytics[selectedAnalyticsType]" :key="`analytic-type-${index}`">
						<FormulateInput
							v-if="value.type == 'radio'"
							type="radio"
							:name="`filter-${name}`"
							:options="value.options"
							:label="value.label"
							:value="value.value"
							@input="updateFilters($event, name, selectedAnalyticsType)"
						/>
						<FormulateInput
							v-else-if="value.type == 'hidden'"
							type="hidden"
							:name="`filter-${name}`"
							:value="value.value"
						/>
					</div>
				</BaseCard>
				<CircleCounter title="Devices" :data="circleCounterData" />
			</template>
		</ContentContainer>
	</div>
</template>

<script>
const moment = require('moment');

import { mapActions } from "vuex";
import ContentContainer from "@/components/Containers/ContentContainer";
import LineChart from "@/components/Chart/LineChart";
import CircleCounter from "@/components/Chart/CircleCounter";
import LabelButtonCard from "@/components/Cards/LabelButtonCard";
import BaseCard from "@/components/Cards/BaseCard";
const colors = require("/colors.config.json");
export default {
	name: "Analytics",
	components: {
		ContentContainer,
		LineChart,
		CircleCounter,
		LabelButtonCard,
		BaseCard
	},
	data() {
		return {
			labels: {
				chart: "Chart",
				firstLabel:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam rutrum molestie nulla eget tristique. Praesent porta at urna sit amet fringilla",
				date_range: "Date range",
				from: "From",
				to: "To",
				type_of_analytics: "Type of analytics",
				user_visits: "User visits",
				yes: "Yes",
				no: "No",
				logged_visits: "Logged visits"
			},
			fromFilter: '',
			toFilter: '',
			circleCounterData: {
				values: [
					{ value: "1580", icon: "custom-monitor", color: `${colors.green}` },
					{ value: "1214", icon: "custom-mobile", color: `${colors.orange}` }
				]
			},
			LabelButtonList: [
				{
					label: "Month",
					actions: [
						{
							type: "button",
							label: "Download",
							variant: "btn-navy",
							action: () => {}
						}
					],
				},
				{
					label: "Month",
					actions: [
						{
							type: "button",
							label: "Download",
							variant: "btn-navy",
							action: () => {}
						}
					]
				},
				{
					label: "Month",
					actions: [
						{
							type: "button",
							label: "Download",
							variant: "btn-navy",
							action: () => {}
						}
					]
				},
				{
					label: "Month",
					actions: [
						{
							type: "button",
							label: "Download",
							variant: "btn-navy",
							action: () => {}
						}
					]
				},
				{
					label: "Month",
					actions: [
						{
							type: "button",
							label: "Download",
							variant: "btn-navy",
							action: () => {}
						}
					]
				}
			],
			// LabelsList items can be either an array of labels or an array of labels with styles
			LabelsList: [
				// { labels: ['25% depth', 'XX dicho', 'XX %'] },
				// { labels: ['50% depth', 'XX dicho', 'XX %'] },
				// { labels: ['75% depth', 'XX dicho', 'XX %'] },
				// { labels: ['100% depth', 'XX dicho', 'XX %'] },
				{
					labels: [
						{ label: "25% depth", style: {} },
						{ label: "XX dicho", style: {} },
						{ label: "XX %", style: {} }
					]
				},
				{
					labels: [
						{ label: "50% depth", style: {} },
						{ label: "XX dicho", style: {} },
						{ label: "XX %", style: {} }
					]
				},
				{
					labels: [
						{ label: "75% depth", style: {} },
						{ label: "XX dicho", style: {} },
						{ label: "XX %", style: {} }
					]
				},
				{
					labels: [
						{ label: "100% depth", style: {} },
						{ label: "XX dicho", style: {} },
						{ label: "XX %", style: {} }
					]
				},
			],
			requestSent: false,
			selectedAnalyticsType: 'user-visits',
			typesOfAnalytics: {},
			allSet: false
		};
	},
	computed: {
		today() {
			return moment(Date.now()).format('YYYY-MM-DD');
		},
		availableAnalytics() {
			let result = {};

			Object.keys(this.typesOfAnalytics).forEach( key => {
				result[key] = this.labels[key.replace('-', '_')];
			} );

			return result;
		}
	},
	methods: {
		...mapActions('analytics', ['getAnalyticsByParameter']),
		...mapActions('notification', ['addNotification']),
		getAnalyticsFn() {
			if (this.requestSent) return;

			this.requestSent = true;
			
			let query = {
				property: this.$route.params.id
			};

			for (const [key, input] of Object.entries(this.typesOfAnalytics[this.selectedAnalyticsType])) {
				query[key] = input.value;
			}

			this.getAnalyticsByParameter({
				from: this.fromFilter,
				to: this.toFilter,
				type: this.selectedAnalyticsType,
				query: query
			}).then((res) => {
				if (res.success) {
					this.requestSent = false;
					console.log('Analytics:', res.data);
				} else if (res.error) {
					this.addNotification({
						variant: "danger",
						msg: res.error,
						labels: this.labels,
					});
				}
			}).catch((err) => console.log(err));
		},
		addTypesOfAnalytics() {
			this.typesOfAnalytics['user-visits'] = {
				type: {
					type: 'hidden',
					value: 'chart'
				},
				login: {
					type: 'radio',
					label: this.labels.logged_visits,
					options: {
						yes: this.labels.yes,
						no: this.labels.no
					},
					value: 'yes'
				}
			};
		},
		updateFilters(value, filterName, analyticsType) {
			this.typesOfAnalytics[analyticsType][filterName].value = value;
			this.getAnalyticsFn();
		}
	},
	mounted() {
		this.addTypesOfAnalytics();
		this.allSet = true;
	},
	watch: {
		fromFilter() {
			this.getAnalyticsFn();
		},
		toFilter() {
			this.getAnalyticsFn();
		}
	}
};
</script>

<style lang="scss" scoped></style>
